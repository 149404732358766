import React, { useEffect, useState } from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  TextField,
} from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { AddSquare16Regular, Delete16Regular } from "@fluentui/react-icons";
import axios from "axios";
import Loading from "../Images/Loading.gif";
import "../Styles/AdminModify.css";
import protectedAxiosInstance from "./helpers/api";

interface AdminModifyProps {
  editModeTab: string;
  setEditMode: (value: string) => void;
}

interface AdminData {
  username: string;
  role: string;
  actions?: string;
}

const AdminModify: React.FC<AdminModifyProps> = ({
  editModeTab,
  setEditMode,
}) => {
  const [adminData, setAdminData] = useState<AdminData[]>([]);
  const [editableRow, setEditableRow] = useState<AdminData | null>(null);
  const [deletedRows, setDeletedRows] = useState<AdminData[]>([]);
  const [showSaveCancel, setShowSaveCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState<string>("");

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await axios.get("/api/Service/GetAllUsers");
        const users = response.data.map((user: any) => ({
          username: user.userName,
          role: user.accessRole,
        }));
        setAdminData(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchUserRole = async () => {
      try {
        const userDetails = localStorage.getItem("user")
        const userName = userDetails ? JSON.parse(userDetails).mail : "" 
        const response = await protectedAxiosInstance.post(
          "/api/service/GetAccessRole",
          {
            UserAlias: userName.split("@")[0],
          },
          {
            withCredentials: true,
          }
        );
        setUserRole(response.data.Table[0].AccessRole);
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchAllUsers();
    fetchUserRole();
  }, []);

  if (isLoading) {
    return (
      <div className="loader-container">
        <img src={Loading} alt="Loading..." className="loader" />
      </div>
    );
  }

  const addUser = () => {
    setEditableRow({ username: "", role: "" });
    setShowSaveCancel(true);
  };

  const saveUser = async () => {
    try {
      if (editableRow) {
        const newUser = {
          userName: editableRow.username,
          accessRole: editableRow.role,
        };
        await axios.post("/api/Service/AddUser", newUser);
        setAdminData([editableRow, ...adminData]);
        setEditableRow(null);
      }

      for (const user of deletedRows) {
        await axios.delete(`/api/Service/DeleteUser/${user.username}`);
      }

      setDeletedRows([]);
      setShowSaveCancel(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const cancelEdit = () => {
    setEditableRow(null);
    setAdminData([...adminData, ...deletedRows]);
    setDeletedRows([]);
    setShowSaveCancel(false);
  };

  const deleteUser = (username: string) => {
    setDeletedRows([
      ...deletedRows,
      ...adminData.filter((user) => user.username === username),
    ]);
    setAdminData(adminData.filter((user) => user.username !== username));
    setShowSaveCancel(true);
  };

  const columns: IColumn[] = [
    {
      key: "username",
      name: "Username",
      fieldName: "username",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "role",
      name: "Role",
      fieldName: "role",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "actions",
      name: "Actions",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: AdminData) => (
        <div
          className="actions"
          style={{
            display: "flex",
            justifyContent: "start",
            marginLeft: "-18px",
          }}
        >
            <Button
            onClick={() => deleteUser(item.username)}
           
            title={
              userRole !== "SuperAdmin" && item.role === "SuperAdmin"
                ? "SuperAdmins Can't be deleted"
                : "Delete this User"
            }
            
            icon={<Delete16Regular />}
            appearance="subtle"
            as="a"
            disabled={
              userRole !== "SuperAdmin" && item.role === "SuperAdmin"
            }
            
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>FTBI Admins</h1>
        <Button
          className="addAdminButton"
          onClick={addUser}
          title="Add Admin"
          icon={<AddSquare16Regular />}
          appearance="subtle"
          style={{ marginTop: "11px" }}
        >
          Add Admin
        </Button>
      </div>
      <div className="ExternalReportContainer" style={{ height: "60vh" }}>
        <DetailsList
          items={editableRow ? [editableRow, ...adminData] : adminData}
          columns={columns.map((col) => ({
            ...col,
            onRender:
              col.key === "username" || col.key === "role"
                ? (item: AdminData, index?: number) =>
                    editableRow && index === 0 ? (
                      <TextField
                        value={
                          editableRow[col.key as keyof AdminData] as string
                        }
                        onChange={(e, newValue) =>
                          setEditableRow({
                            ...editableRow,
                            [col.key]: newValue || "",
                          })
                        }
                      />
                    ) : (
                      item[col.key as keyof AdminData]
                    )
                : col.onRender,
          }))}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          isHeaderVisible={true}
          styles={{
            headerWrapper: {
              selectors: {
                ".ms-DetailsHeader-cellName": {
                  justifyContent: "center",
                },
              },
            },
          }}
        />
      </div>
      {showSaveCancel && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            className="saveButton"
            onClick={saveUser}
            appearance="subtle"
            style={{ marginRight: "10px" }}
          >
            Save
          </Button>
          <Button onClick={cancelEdit} appearance="secondary">
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

export default AdminModify;